import React from 'react';
import PropTypes from 'prop-types';

const Logo = ({height, width}) => {
	const style = {height, width};

	return (
		<div style={style}>
			<svg width={width} height={height} viewBox="0 0 164 70" version="1.1">
				<defs>
					<polygon id="path-1" points="0 0.1967 163.747753 0.1967 163.747753 9.849525 0 9.849525"/>
				</defs>
				<g id="Desktop" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
					<g id="Legendary-Concerts" transform="translate(-639.000000, -24.000000)">
						<g id="Header-Nav" transform="translate(1.000000, -1.000000)">
							<g id="Logo">
								<g transform="translate(638.000000, 25.000000)">
									<polygon
										id="Fill-1"
										fill="#FFFFFF"
										points="146.848848 15.3125 136.71875 25.6822701 143.684775 32.8125 160.78125 15.3125"
									/>
									<g id="Group-23">
										<path
											d="M143.601442,32.83504 C144.55061,32.37549 145.611383,32.11019 146.736233,32.11019 C150.701898,32.11019 153.927521,35.31724 153.927521,39.25929 C153.927521,40.37754 153.660656,41.43244 153.198746,42.37569 L160.334056,49.46984 C162.498907,46.62784 163.785354,43.09074 163.785354,39.25929 C163.785354,29.91359 156.137084,22.31019 146.736233,22.31019 C142.882876,22.31019 139.324903,23.58944 136.465779,25.74124 L143.601442,32.83504 Z"
											id="Fill-2"
											fill="#FFFFFF"
										/>
										<path
											d="M149.993965,45.624915 C149.014871,46.122265 147.909385,46.408915 146.736303,46.408915 C142.770638,46.408915 139.544662,43.201515 139.544662,39.259465 C139.544662,38.093265 139.833004,36.994265 140.333641,36.020915 L133.21875,28.947765 C131.006371,31.806915 129.686829,35.381815 129.686829,39.259465 C129.686829,48.605515 137.335099,56.208915 146.736303,56.208915 C150.636484,56.208915 154.232833,54.897115 157.108503,52.697715 L149.993965,45.624915 Z"
											id="Fill-4"
											fill="#0091D6"
										/>
										<polygon
											id="Fill-6"
											fill="#0091D6"
											points="84.5218314 33.926725 84.5218314 54.788125 94.2261635 54.788125 94.2261635 43.574475"
										/>
										<polygon
											id="Fill-8"
											fill="#FFFFFF"
											points="104.118534 34.639045 84.5215146 15.481095 84.5215146 27.491695 104.366388 47.220145 114.010869 37.621745 114.010869 54.787845 123.715201 54.787845 123.715201 15.824095"
										/>
										<polygon id="Fill-10" fill="#0091D6" points="44.8845427 36.97659 44.8845427 54.78809 62.8011534 54.78809"/>
										<path
											d="M65.1159486,30.519195 L57.1835619,30.519195 L54.5891916,27.940045 L54.5891916,24.582145 L65.1159486,24.582145 C66.7625587,24.582145 68.1021677,25.913195 68.1021677,27.550495 C68.1021677,29.187445 66.7625587,30.519195 65.1159486,30.519195 M78.835235,27.291145 C78.835235,26.349645 78.7194055,25.412695 78.4926754,24.504445 C77.2083406,19.393745 72.6064931,15.824095 67.3008668,15.824095 L44.8845074,15.824095 L44.8845074,30.541595 L54.865563,40.464445 L65.1141882,40.464445 C66.7604463,40.464445 68.1004074,41.796195 68.1004074,43.432795 C68.1004074,45.069745 66.7604463,46.401495 65.1141882,46.401495 L60.8376492,46.401495 L69.1213972,54.636645 C73.6574085,53.922295 77.3646577,50.595195 78.4926754,46.107145 C78.7194055,45.200295 78.835235,44.262295 78.835235,43.321495 C78.835235,40.326545 77.6438456,37.439745 75.5472958,35.306145 C77.6438456,33.172895 78.835235,30.285745 78.835235,27.291145"
											id="Fill-12"
											fill="#FFFFFF"
										/>
										<path
											d="M38.7258265,31.055115 C37.6520269,26.258015 34.8083942,22.069915 30.7180978,19.263615 C27.4414247,17.013815 23.5958138,15.824165 19.5970544,15.824165 C13.9027478,15.824165 8.52635629,18.272765 4.79727904,22.543465 L11.7072676,29.412915 C11.9857514,29.047165 12.2906401,28.694715 12.6191172,28.368865 C14.4825996,26.515265 16.9607883,25.494665 19.5970544,25.494665 C22.2336725,25.494665 24.7122133,26.515265 26.5753437,28.368865 C27.497051,29.284815 28.2156166,30.346715 28.7099165,31.524465 L28.9112979,32.003615 L38.938122,32.003615 L38.7258265,31.055115 Z"
											id="Fill-14"
											fill="#0091D6"
										/>
										<path
											d="M19.3338503,38.53451 L24.8091721,43.85591 C23.167843,44.85376 21.1392419,45.11731 19.5971952,45.11731 C16.9609291,45.11731 14.4827404,44.09671 12.6189059,42.24346 C10.7547194,40.39126 9.7280965,37.92691 9.7280965,35.30611 C9.7280965,34.85881 9.76330304,34.41256 9.82245004,33.97436 L2.17030747,26.36711 L1.90449806,26.91976 C0.640935153,29.54791 0.000176032725,32.36961 0.000176032725,35.30611 C0.000176032725,40.51026 2.03863499,45.40256 5.73989907,49.08211 C9.44116316,52.76166 14.362334,54.78781 19.5971952,54.78781 C23.0995423,54.78781 26.4969739,53.87116 29.4891782,52.12781 L29.4891782,54.71361 L39.1942144,54.71361 L39.1942144,38.53451 L19.3338503,38.53451 Z"
											id="Fill-16"
											fill="#FFFFFF"
										/>
										<polygon
											id="Fill-18"
											fill="#FFFFFF"
											points="163.747753 69.653 0 69.653 0 60 74.8653098 60 82.4815417 67.57155 161.65402 67.57155 161.65402 60 163.747753 60"
										/>
										<g id="Group-22">
											<mask id="mask-2" fill="white">
												<use xlinkHref="#path-1"/>
											</mask>
											<g id="Clip-21"/>
											<polygon
												id="Fill-20"
												fill="#FFFFFF"
												mask="url(#mask-2)"
												points="0 0.196525 163.747753 0.196525 163.747753 9.849525 78.7257075 9.849525 70.9700577 2.277975 2.09373323 2.277975 2.09373323 9.849525 0 9.849525"
											/>
										</g>
									</g>
								</g>
							</g>
						</g>
					</g>
				</g>
			</svg>
		</div>
	);
};

Logo.propTypes = {
	height: PropTypes.any,
	width: PropTypes.any
};

Logo.defaultProps = {
	height: '100%',
	width: '100%'
};

export default Logo;
